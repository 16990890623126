.chef-card {
  position: relative;
  background: #fff;
  padding: 2px;
  border-radius: 8px;
  max-width: 368px;
  max-height: 90px;
  width: 80%;
  padding: 10px;
  text-align: left;
  font-size: 14px;
}

.chef-icon {
  width: 83px;
  height: 83px;
  border-radius: 50%;
  position: absolute;
  left: 15px;
  top: -40px;
}
