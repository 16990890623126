.title {
    margin: 0;
    font-weight: bold;
    font-size: 12px;
    height: 38px;
}

.text-secondary {
    font-size: 12px;
    margin: 0;
    color: #100605;

}

.variant {
    color: #9D9D9D
}

.dish-label {
    height: 12px;
    width: 12px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.card-action {
    color: #FF970F;
    font-size: 12px;
    text-decoration: none;
}