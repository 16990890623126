body {
  margin: 0;
  font-family: "Quicksand";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}

.App {
  position: relative;
}

header {
  position: absolute;
  width: 100%;
  padding: 30px;
  z-index: 1;
}

nav {
  display: flex;
  justify-content: space-between;
}
.logo-wrapper {
  display: flex;
  align-items: flex-start;
}

.version-tag {
  font-size: 8px;
  background-color: #108263;
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
  margin-left: 10px;
}

.location {
  display: flex;
  justify-content: center;
}

.location img {
  height: 19px;
  width: 16px;
}

.location p {
  margin: 0;
  margin-left: 10px;
  color: #fff;
  font-size: 12px;
}

.banner {
  display: flex;
  align-items: center;
  padding: 90px 0 35px 0;
  background-image: url("../assets/bg.png");
  background-size: cover;
  position: relative;
  flex-wrap: wrap;
  flex-direction: column-reverse;
}

.custom-carousel {
  width: 100%;
  text-align: center;
}

.banner-recipe-img {
  width: 400px;
}
.indicator-icon {
  color: #ffffff !important;
}

.active-indicator-icon {
  color: #ff970f !important;
}
.info {
  z-index: 1;
  text-align: center;
  max-width: 500px;
  color: #fff;
}

.info h2 {
  font-weight: bold;
  font-size: 24px;
  color: #b8ffd9;
  margin: 0;
}

.info h1 {
  font-weight: bold;
  font-size: 36px;
  color: #fff;
  margin: auto;
  margin-bottom: 48px;
  max-width: 400px;
}

.chef-card-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff970f;
  color: #fff;
  font-size: 16px;
  border-radius: 63px;
  height: 52px;
  width: 205px;
  font-weight: bold;
  opacity: 1;
  margin: auto;
}
.disabled-btn {
  opacity: 0.5;
  cursor: default;
}
.recipes {
  padding: 10px 0;
  background: transparent linear-gradient(110deg, #f2f2f2 0%, #e9e1d8 100%) 0%
    0% no-repeat padding-box;
}
.recipes h2 {
  text-align: center;
  font-weight: bolder;
  font-size: 36px;
}

.recipes-wrapper {
  width: 85%;
  margin: auto;
  padding: 30px 0;
}

footer {
  height: 67px;
  background-color: black;
}


@media only screen and (min-width: 1200px) { 

  .info {
    position: absolute;
    top: 230px;
    left: 50px;
    text-align: left;
  }

  .info h1 {
    font-size: 50px;
    max-width: unset;
  }

  .info h2 {
    font-size: 38px;
  }

  .chef-card-wrapper {
    position: absolute;
    bottom: 45px;
  }
  .custom-carousel {
    width: 100%;
    text-align: right;
  }
  .banner-recipe-img {
    width: 870px;
  }
  .btn {
    margin: unset;
  }


}