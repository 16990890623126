/* .custom-modal {
  overflow: visible;
  border-radius: 24px;
} */

.modal-title {
  max-width: 545px;
  margin: auto !important;
}

.modal-title h2 {
  padding: 8px 24px;
  text-align: center;
  font-size: 24px !important;
  font-weight: bold !important;
}

.dialog-content {
  padding-bottom: 60px !important;
}

.modal-content {
  max-width: 480px;
  text-align: center;
  margin: auto !important;
}

.modal-content p {
  color: #100605 !important;
}

.close-modal {
  position: absolute;
  /* height: 27px;
  width: 27px;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold; */
  top: 10px;
  right: 20px;
  /* background-color: #ffffff;
  cursor: pointer; */
}

.option-chip {
  margin: 4px !important;
}

.form-field {
  text-align: center;
}

.form-field h4 {
  color: #707070;
}

.selected-option {
  background-color: #FFA3041C !important;
  color: #FF970F !important;
  border: 1px solid #FF970F !important;
}

.hide {
  visibility: hidden;
}